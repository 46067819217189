<template>
  <div class="d-flex flex-column flex-grow-1 rounded mr-md-3">
    <BaseToolBar
      title="Rdvs des clients"
      subtitle="Liste des rdvs / visites réalisées"
    >
      <button
        class="btn btn-primary"
        v-tooltip="'Ajouter un rdv'"
        @click="createRdv(id)"
      >
        Ajouter
      </button>
    </BaseToolBar>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="w-100" v-if="rdvs">
        <div v-for="rdv in rdvs" :key="rdv.id" class="w-100">
          <RdvItem :rdv="rdv" :lists="lists"></RdvItem>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import RdvItem from "@/components/clients/RdvItem.vue";
export default {
  components: { BaseToolBar, RdvItem },
  data() {
    return {
      lists: {},
      feedback: {}
    };
  },
  props: {},
  computed: {
    ...mapFields("clients", ["client.id"]),
    ...mapMultiRowFields("clients", ["rdvs"])
  },
  methods: {
    ...mapActions({
      _createRdv: "clients/createRdv"
    }),
    createRdv: function(d) {
      this.feedback = {};
      this._createRdv(d).catch(error => this.errHandler(error));
    },
    errHandler: function(error) {
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
    }
  },
  mounted() {}
};
</script>
