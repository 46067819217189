<template>
  <container-list-extend title="Visite" class="d-flex flex-column w-100">
    <template v-slot:title>
      <div class="p-1 p-md-2 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <h6
          class="m-0 ml-1"
          v-if="rdv.rdv_date"
        >RDV du {{ $moment(rdv.rdv_date).format('DD/MM/YYYY') }}</h6>
        <h6 class="m-0 ml-1" v-else>Nouveau</h6>
      </div>
      <div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
        <small>
          <div v-if="rdv.rdv_date">
            Le :
            {{ $moment(rdv.rdv_date).format("DD/MM/YYYY") }}
          </div>
        </small>
      </div>
    </template>
    <template v-slot:form>
      <base-form deleteBtn @click="updateRdv(rdv)" @delete="deleteRdv(rdv)" :loading="loading">
        <base-form-row row>
          <base-inputDatePicker
            v-model="rdv.rdv_date"
            inputText="Date de rdv"
            :errors="feedback.rdv_date"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Type"
            v-model.number="rdv.rdv_type_id"
            :options="data.rdvTypes"
            :errors="feedback.rdv_type_id"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-input-area v-model="rdv.commentaire" text="Synthèse" :errors="feedback.commentaire"></base-input-area>
        </base-form-row>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
//import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";

export default {
  name: "FormationsItem",
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInputDatePicker,
    BaseInputArea,
    //BaseInput,
    BaseSelect
  },
  data() {
    return {
      feedback: {},
      loading: false
    };
  },
  props: {
    rdv: {
      Type: Object,
      Requierd: true,
      Default: {}
    },
    lists: {
      Type: Object,
      Requierd: true,
      Default: {}
    }
  },
  computed: {
    ...mapGetters({ data: "clients/data" })
  },
  methods: {
    ...mapActions({
      _updateRdv: "clients/updateRdv",
      _deleteRdv: "clients/deleteRdv"
    }),
    updateRdv: function(rdv) {
      this.feedback = {};
      this.loading = true;
      this._updateRdv(rdv)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteRdv: function(rdv) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null
      this.feedback = {};
      this.loading = true;
      this._deleteRdv(rdv)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style lang="css"></style>